import React from "react"
import "./index.scss"
import { useStaticQuery, graphql } from "gatsby"
const Privacy = ({ _data, _title }) => {
  let title = {}
  let privacy = []
  try {
    const data = useStaticQuery(graphql`
      {
        mdx(fileAbsolutePath: { regex: "/(privacy-src)/" }) {
          exports {
            privacy {
              description
              title
            }
          }
          frontmatter {
            titlePrivacy
            paraprivacy
            paraprivacy1
            subTitleprivacy
          }
        }
      }
    `)
    privacy = data.mdx.exports.privacy
    title = data.mdx.frontmatter
  } catch {
    privacy = _data
    title = _title
  }
  return (
    <div id="Privacy" className="Privacy " data-testid="Privacy">
      <div className="titleSection sect ">
        <h1 className="titlePrivacy">{title?.titlePrivacy}</h1>

        <p className="para">{title?.paraprivacy}</p>
        <p className="para">{title?.paraprivacy1}</p>
      </div>
      <div className="indexSection sect">
        <p className="titleh2">{title?.subTitleprivacy}</p>
        {privacy?.map((item, index) => {
          return (
            <div key={index}>
              <p>
                <a href={`#${item.title}`} className="link">
                  {item.title}
                </a>
              </p>
            </div>
          )
        })}
      </div>
      {privacy?.map((item, index) => {
        return (
          <div key={index} className="sect">
            <h2 id={item.title} className="titleh2">
              {item.title}
            </h2>
            <p className="para">{item.description}</p>
          </div>
        )
      })}
    </div>
  )
}

export default Privacy
