import React from "react"
import PrivacyPolicy from "../components/privacy-policy"
import Layout from "../components/layout/t1/layout"

const PrivacyPage = () => {
  return (
    <Layout>
      <PrivacyPolicy />
    </Layout>
  )
}

export default PrivacyPage
