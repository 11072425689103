import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../seo"
import Privacy from "./privacy"

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <Seo
        title="Privacy Policy"
        description="MAXapps is committed to preserving the privacy of all visitors and protecting any personal data that you may provide to us."
      />
      <Helmet>
        <meta property="og:title" content="MAXapps: Privacy Policy" />
        <meta
          property="og:description"
          content="MAXapps is committed to preserving the privacy of all visitors and protecting any personal data that you may provide to us."
        />
        <meta
          property="og:image"
          content="https://maxapps-maximo.com/og/privacy-policy.jpg"
        />
        <meta
          property="og:url"
          content="https://maxapps-maximo.com/privacy-policy"
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@maxapps" />
        <meta name="twitter:title" content="MAXapps: Privacy Policy" />
        <meta
          name="twitter:description"
          content="MAXapps is committed to preserving the privacy of all visitors and protecting any personal data that you may provide to us."
        />
        <meta
          name="twitter:image"
          content="https://maxapps-maximo.com/og/privacy-policy.jpg"
        />
      </Helmet>
      <Privacy />
    </div>
  )
}

export default PrivacyPolicy
